const firebaseConfig = {
    // apiKey: "AIzaSyDgAZD8vLUDZ9WhI_BasariQvLHTlWVZ-s",
    // authDomain: "renter-eaf0f.firebaseapp.com",
    // databaseURL: "https://renter-eaf0f-default-rtdb.firebaseio.com",
    // projectId: "renter-eaf0f",
    // storageBucket: "renter-eaf0f.appspot.com",
    // messagingSenderId: "109409391302",
    // appId: "1:109409391302:web:7d4df0aba331368f9abdc7",
    // measurementId: "G-1NNVG1G3V1"
    apiKey: "AIzaSyCnpLuJToWDrG1x4J-z-hok-Euufj5IG9Q",
    authDomain: "renter-3e51e.firebaseapp.com",
    databaseURL: "https://renter-3e51e-default-rtdb.firebaseio.com",
    projectId: "renter-3e51e",
    storageBucket: "renter-3e51e.appspot.com",
    messagingSenderId: "144014101437",
    appId: "1:144014101437:web:b125d3d84e758d718520d4",
    measurementId: "G-K97ZM4PE6R"
};
  
export default firebaseConfig