import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Dropdown  } from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import Pagination from "../pagination";
import { RouteContext } from "../../../contexts/route.context";
import { translateTerm } from "../../../util/helper";
import { useIntersection } from "../../../util/useIntersection";


const LesseeInvoices = () => {
  const intl = useIntl();
  const { getInvoiceActive, invoiceActive, invoiceActiveLoading, setInvoiceActiveLoading, 
    getInvoiceHistory, invoiceHistory, invoiceHistoryLoading, setInvoiceHistoryLoading, } = useContext(RouteContext);
  const [key, setKey] = useState('active');
  const [openDetails, setOpenDetails] = useState({
    set0 : false,
    set1 : false,
    set2 : false,
    set3 : false,
    set4 : false,
    set5 : false,
    set6 : false,
    set7 : false,
    set8 : false,
  });
  const [openDetailsActive, setOpenDetailsActive] = useState();
  const [openDetailsHistory, setOpenDetailsHistory] = useState();
  const [numInv, setNumInv] = useState(16);
  const [pageInv, setpageInv] = useState(1);
  const [lesseeRole, setlesseeRole] = useState(`lessee`);

  const elementRef = useRef(null);
  const [newInvoiceHistory, setNewInvoiceHistory] = useState();
  const [newInvoiceActive, setNewInvoiceActive] = useState();
  const isVisible = useIntersection(elementRef, "0px");
  const [moreLoading, setMoreLoading] = useState(false);

  useEffect(() => {
    setInvoiceActiveLoading(true);

    if(key === "active"){
      getInvoiceActiveFunc();
    }
    else{
      getInvoiceHistoryFunc();
    }
    
  }, [key]);

  const getInvoiceHistoryFunc = async () => {
      let result  = await getInvoiceHistory(numInv, pageInv, lesseeRole);
      setNewInvoiceHistory(result);
  }

  const getInvoiceActiveFunc = async () => {
    let result  = await getInvoiceActive(numInv, pageInv, lesseeRole);
    setNewInvoiceActive(result);
  }

  useEffect(() => {
    if (isVisible && (newInvoiceActive || newInvoiceHistory)) {
    // Trigger a function when the div is visible on view port
    triggerFunc();
    }
  }, [isVisible]);

  const scrollPosts = async () => {
    if(key === 'active') {
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getInvoiceActive(numInv, newInvoiceActive.paginatorInfo.currentPage+1, lesseeRole);
        if(newInvoiceActive.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              newInvoiceActive.data.push(dat);
            })
            newInvoiceActive.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
    else{
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getInvoiceHistory(numInv, newInvoiceHistory.paginatorInfo.currentPage+1, lesseeRole);
        if(newInvoiceHistory.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              newInvoiceHistory.data.push(dat);
            })
            newInvoiceHistory.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
  }

  const triggerFunc = () => {
    console.log("Halo")
    if(key === 'active') {
      if(newInvoiceActive){
          if(newInvoiceActive.paginatorInfo.hasMorePages){
            scrollPosts()
            }
            else{
              // setNoMorePosts(true);
            }
        }
    }
    else{
      if(newInvoiceHistory){
        if(newInvoiceHistory.paginatorInfo.hasMorePages){
          scrollPosts()
          }
          else{
            // setNoMorePosts(true);
          }
      }
    }
   }



  useEffect(() => {
    if(invoiceActive && invoiceActive.data.length !== 0) {
      let openIvObj = new Object();
      for (let index = 0; index < invoiceActive.data.length ; index++) {
        let setObj = "set" + index;
        openIvObj[setObj] = false;
      }
      setOpenDetailsActive(openIvObj);
    }
  }, [invoiceActive]);

  useEffect(() => {
    if(invoiceHistory && invoiceHistory.data.length !== 0) {
      let openIvObj = new Object();
      for (let index = 0; index < invoiceHistory.data.length ; index++) {
        let setObj = "set" + index;
        openIvObj[setObj] = false;
      }
      setOpenDetailsHistory(openIvObj);
    }
  }, [invoiceHistory]);


  const onClickOpenDetailsActive = (position, positionFinal) => {
    setInvoiceActiveLoading(true);
    if(positionFinal) {
      openDetailsActive[position] = false;
      setOpenDetailsActive(openDetailsActive);

      setTimeout(() => setInvoiceActiveLoading(false), 200);
    }
    else {
      openDetailsActive[position] = true;
      setOpenDetailsActive(openDetailsActive);
      
      setTimeout(() => setInvoiceActiveLoading(false), 200);
    }
  }


  const onClickOpenDetailsHistory = (position, positionFinal) => {
    // console.log(newInvoiceHistory)
    // console.log(openDetailsHistory)
    // console.log(position, positionFinal);
    setInvoiceHistoryLoading(true);
    if(positionFinal) {
      openDetailsHistory[position] = false;
      setOpenDetailsHistory(openDetailsHistory);

      setTimeout(() => setInvoiceHistoryLoading(false), 200);
    }
    else {
      openDetailsHistory[position] = true;
      setOpenDetailsHistory(openDetailsHistory);
      
      setTimeout(() => setInvoiceHistoryLoading(false), 200);
    }
  }

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-4 mt-0 product-categories justify-content-start"
      >
        <Tab eventKey="active" title={intl.formatMessage({
          id: 'active.text',
          defaultMessage: 'Active',
          description: 'Active',
        })}>
          <div className="my-profile-div">
            <div className="row">
              <div className="col-12">
                <p className="fw700 cblue fs20 mb-2">
                  <FormattedMessage 
                    id="activeinvoices.text"
                    defaultMessage="Active Invoices"
                    description="Active Invoices"
                  />
                </p>
                {/* <p className="cblack fs14 mb-3">
                  <FormattedMessage 
                    id="activeindesc.text"
                    defaultMessage="Active Invoices desc"
                    description="Active Invoices desc"
                  />
                </p> */}

                <div className="div-2px mb-4" />

              </div>


              {!invoiceActiveLoading && invoiceActive ? (
                <>
                  {invoiceActive.data.length !== 0 && invoiceActive.data.map((inv, idx) => {
                    let positionFinal, position;
                    if(openDetailsActive) {
                      position = "set"+idx;
                      positionFinal = openDetailsActive[position];
                    }

                    return (
                      <div key={idx} className="col-12 col-lg-6">
                        <div className="invoice-per-div">
                          <p className="mb-3 fs12 cgray d-flex justify-content-between align-items-center">
                            {inv.payment_date}
                            <span className="span-tag active">
                              <FormattedMessage 
                                id="active.text"
                                defaultMessage="Active"
                                description="Active"
                              />
                            </span>
                          </p>
                          <p className="fs14 cblack fw600 mb-0 d-flex justify-content-between align-items-center">
                            <FormattedMessage 
                              id="leasepayment.text"
                              defaultMessage="Lease Payment"
                              description="Lease Payment"
                            />

                            {positionFinal && (
                              <span className="fw700 ">
                                {/* SAR {inv.lessor_commission} */}
                                SAR {inv.total}
                              </span>
                            )}
                            
                          </p>
                          <p className="fw400 fs12 cgray mb-0">INV-{inv.id}</p>


                          {positionFinal && (
                            <div className="invoice-detail-div">
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="leaseterm.text"
                                  defaultMessage="Lease Term"
                                  description="Lease Term"
                                />: 
                                <span className="fw400"> {inv.booking && translateTerm(inv.booking.lease_term)}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="startdate.text"
                                  defaultMessage="Start Date"
                                  description="Start Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.booking_date}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="enddate.text"
                                  defaultMessage="End Date"
                                  description="End Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.end_date}</span>
                              </p>
                            </div>
                          )}


                          <p className="d-flex fs12 cred mb-0 cur-pointer align-items-center justify-content-end mt-2"
                            onClick={() => onClickOpenDetailsActive(position, positionFinal)}
                          >
                            {positionFinal ? 
                              intl.locale === "en" ? (
                                "Hide lease details"
                              ) : (
                                "إخفاء تفاصيل عقد الإيجا"
                              )
                             : intl.locale === "en" ? (
                                "View lease details"
                             ) : (
                              "عرض تفاصيل عقد الإيجار"
                            )}

                            {positionFinal ? (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 6.25L6 1L11.25 6.25" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            ) : (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.25 0.75L6 6L0.75 0.75" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            )}
                          </p>
                        </div>
                      </div>
                      
                    )
                    
                    })}
                    {moreLoading &&
                          <div className="loader"></div>
                    }
                  {/* <Pagination useFunction="getInvoiceActive" posts={invoiceActive} setPostsLoading={setInvoiceActiveLoading} numLease={numInv} /> */}
                </>
              )
              : (
                <div className="loader"></div>
              )}
            </div>
          </div>

          

        </Tab>
        <Tab eventKey="history" title={intl.formatMessage({
          id: 'history.text',
          defaultMessage: 'History',
          description: 'History',
        })}>
          
          <div className="my-profile-div">
            <div className="row">
              <div className="col-12">
                <p className="fw700 cblue fs20 mb-2">
                  <FormattedMessage 
                    id="historyinvoices.text"
                    defaultMessage="History Invoices"
                    description="History Invoices"
                  />
                </p>
                {/* <p className="cblack fs14 mb-3">
                  <FormattedMessage 
                    id="activeindesc.text"
                    defaultMessage="Active Invoices desc"
                    description="Active Invoices desc"
                  />
                </p> */}

                <div className="div-2px mb-4" />

              </div>

              {!invoiceHistoryLoading && newInvoiceHistory ? (
                <>
                  {newInvoiceHistory.data.length !== 0 && newInvoiceHistory.data.map((inv, idx) => {
                    let positionFinal, position;
                    if(openDetailsHistory) {
                      position = "set"+idx;
                      positionFinal = openDetailsHistory[position];
                    }

                    return (
                      <div className="col-12 col-lg-6">
                        <div className="invoice-per-div">
                          <p className="mb-3 fs12 cgray d-flex justify-content-between align-items-center">
                            {inv.payment_date}
                            {inv.booking?.refund ? (
                              <span className="span-tag cancel">
                                <FormattedMessage 
                                  id="cancelled.text"
                                  defaultMessage="Cancelled"
                                  description="Cancelled"
                                />
                              </span>
                            ) : (
                              <span className="span-tag complete">
                                <FormattedMessage 
                                  id="complete.text"
                                  defaultMessage="Complete"
                                  description="Complete"
                                />
                              </span>
                            )}
                          </p>
                          <p className="fs14 cblack fw600 mb-0 d-flex justify-content-between align-items-center">
                            <FormattedMessage 
                              id="leasepayment.text"
                              defaultMessage="Lease Payment"
                              description="Lease Payment"
                            />

                            {positionFinal && (
                              <span className="fw700 ">
                                {/* SAR {inv.lessor_commission} */}
                                SAR {inv.total}
                              </span>
                            )}
                            
                          </p>
                          <p className="fw400 fs12 cgray mb-0">INV-{inv.id}</p>


                          {positionFinal && (
                            <div className="invoice-detail-div">
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="leaseterm.text"
                                  defaultMessage="Lease Term"
                                  description="Lease Term"
                                />: 
                                <span className="fw400"> {inv.booking && translateTerm(inv.booking.lease_term)}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="startdate.text"
                                  defaultMessage="Start Date"
                                  description="Start Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.booking_date}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="enddate.text"
                                  defaultMessage="End Date"
                                  description="End Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.end_date}</span>
                              </p>
                            </div>
                          )}


                          <p className="d-flex fs12 cred mb-0 cur-pointer align-items-center justify-content-end mt-2"
                            onClick={() => onClickOpenDetailsHistory(position, positionFinal)}
                          >
                            {positionFinal ? 
                              intl.locale === "en" ? (
                                "Hide lease details"
                              ) : (
                                "إخفاء تفاصيل عقد الإيجا"
                              )
                             : intl.locale === "en" ? (
                                "View lease details"
                             ) : (
                              "عرض تفاصيل عقد الإيجار"
                            )}

                            {positionFinal ? (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 6.25L6 1L11.25 6.25" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            ) : (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.25 0.75L6 6L0.75 0.75" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            )}
                          </p>
                        </div>
                      </div>
                    )
                    })}
                    {moreLoading &&
                          <div className="loader"></div>
                    }
                  {/* <Pagination useFunction="getInvoiceHistory" posts={invoiceHistory} setPostsLoading={setInvoiceHistoryLoading} numLease={numInv} /> */}
                </>
              ) : (
                <div className="loader"></div>
              )}
              
            </div>
          </div>

        </Tab>
      </Tabs>
      <div ref={elementRef}></div>
    </div>
  )
}

export default LesseeInvoices;
